<template>
  <div class="ma-4">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card flat>
        <v-card-title>
          <span class="text-h5"> Upload Transcripts</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col> Zip archive containing transcripts.csv </v-col>
            </v-row>
            <v-row>
              <v-col>
                <VueFileAgent
                  ref="vueFileAgent"
                  :theme="'list'"
                  :multiple="false"
                  :deletable="true"
                  :meta="true"
                  :accept="'.zip'"
                  :maxSize="'1300MB'"
                  :maxFiles="1"
                  :helpText="'Click here to choose your ensemble file or drag it here.'"
                  :errorText="{
                    type: 'Invalid file type. Only zip Allowed',
                    size: 'Files should not exceed 1300MB in size',
                  }"
                  @select="filesSelected($event)"
                  @beforedelete="onBeforeDelete($event)"
                  @delete="fileDeleted($event)"
                  @upload="onUploaded($event)"
                  @upload:error="onUploadError($event)"
                  v-model="fileRecords"
                ></VueFileAgent>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="body-2 orange--text">
                {{ errorMessage }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn> -->
          <v-btn :disabled="!fileRecordsForUpload.length" color="primary " @click="uploadFiles"> Upload </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>
<script>
import config from "@/config.js";
import VueFileAgentPlugin from "vue-file-agent";
// eslint-disable-next-line no-unused-vars
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
const VueFileAgent = VueFileAgentPlugin.VueFileAgent;
import store from "@/store";
import  ApiIntegrface  from "@/api/api.js"

export default {
  name: "Transcrips",
  components: { VueFileAgent },
  data: () => ({
    valid: true,

    fileRecords: [],
    errorMessage: null,
    uploadUrl: config.backendEndpoint + "/api/v1/transcripts/upload/",
    uploadHeaders: { "Content-Type": "application/zip", Authorization: "Bearer " + store.state.Token },
    fileRecordsForUpload: [], // maintain an upload queue
  }),
  methods:{
    onUploadError(err) {
      const rsp = err[0]?.response || null;
      if (rsp.status == 400) {
        this.errorMessage = " Invalid File uploaded! " + JSON.stringify(rsp.data);
        // console.log(rsp.data?.file)
      }
    },
    onUploaded(responses) {
      var succeeded = true;
      for (var rsp of responses) {
        if (rsp.error) {
          console.log("onUploaded", responses);
          succeeded = false;
          break;
        }
      }
      if (succeeded) {
        this.$root.notify.show({ message: "The transcript file imported successfully " });
        // this.close();
      }
    },
    uploadFiles: function () {
      const _this = this;

      const api = new ApiIntegrface()
      if(api.isRefreshRequired()){
        // console.log('Refresh is required')
        api.doRefreshToken().then(()=>{
          // console.log('Refreshed')
          var url = _this.uploadUrl + _this.fileRecordsForUpload[0].name();
          _this.$refs.vueFileAgent.upload(url, _this.uploadHeaders, _this.fileRecordsForUpload);
        })
      } else {
        // console.log('No refresh is needed')
        var url = _this.uploadUrl + _this.fileRecordsForUpload[0].name();
        _this.$refs.vueFileAgent.upload(url, _this.uploadHeaders, _this.fileRecordsForUpload);
      }
    },
    deleteUploadedFile: function (fileRecord) {
      this.errorMessage = null
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      this.errorMessage = null
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      this.errorMessage = null
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  }
};
</script>
